import React from 'react'
import { graphql } from 'gatsby'
import Page from '../components/page'
import SEO from '../components/seo'
import Layout from '../components/layout'

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      title
      slug
      content {
        _rawText
      }
    }
  }
`

const PageTemplate = ({data}) => {
  const page = data && data.page
  return (
    <Layout>
      {page && <SEO title={page.title || 'Untitled'} />}
      {page && <Page {...page} />}
    </Layout>
  )
}

export default PageTemplate
