import React from 'react'
import BlockContent from './block-content'
import {Container} from 'reactstrap'

let Page = ({content, title}) => (
  <Container className="py-5">
    <div>
      <h1>{title}</h1>
      {content.map(c => {
        return c._rawText && <BlockContent blocks={c._rawText || []} />
      })}
    </div>
  </Container>
)

export default Page
